// ----------------------------------------------------------------------------------------------------
// Cookie Bar Engine v1.1
//
// ©2012 Semantic Ltd. All Rights Reserved.
// ----------------------------------------------------------------------------------------------------

var _cbBar = null, _cbTimeout = null, _cbBarHeight = 0;

(function() {
	if ($) {
		$(document).ready(function() {				   
			// Add cookie bar stylesheet
			var _cbCss = document.createElement("link"); _cbCss.type = "text/css"; _cbCss.rel = "stylesheet"; _cbCss.media = "screen"; _cbCss.href = "/js/cookiebar/cookiebar.css";
			document.getElementsByTagName("head")[0].appendChild(_cbCss);
			
			// Initialise bar
			try { initCookieBar(); } catch(e) { }
		});
	}
})();

// Function to show the cookie bar to users that haven't seen it yet
function initCookieBar() {
	if (readCookie("dontShowCookieBar") == null) setTimeout("showCookieBar();", 1000);
}

// Function to start/restart cookie bar timeout (15 seconds)
function timeoutCookieBar() {
	if (_cbTimeout) clearTimeout(_cbTimeout);
	_cbTimeout = setTimeout("hideCookieBar();", 10000);
}

// Function to slide invitation bar into view
function showCookieBar() {
	if ($) {
		// Create cookie bar element
		$("body").append("<div id=\"cookieBarArea\"></div>");
		$("#cookieBarArea").append("<div id=\"cookieBar\" onmousemove=\"timeoutCookieBar();\"></div>");
		_cbBar = $("#cookieBar");
		
		// Add placement text areas and buttons
		//_cbBar.append("<p id=\"cookieBarText\"></p><div id=\"cookieBarButtons\"><a class=\"smallNextButton\" href=\"javascript:void(0);\" title=\"Continue\" onclick=\"hideCookieBar();\">" + _cbButton + "</a></div>");
		_cbBar.append("<p id=\"cookieBarText\"></p>");
			
		// Update text
		_cbBar.find("#cookieBarText").html(_cbMessage + " <a href=\"javascript:void(0);\" title=\"Continue\" onclick=\"hideCookieBar();\">" + _cbButton + "</a>");
		
		// Calculate the right height
		var barHeight = _cbBar.find("#cookieBarText").height() + 20;
		//if (barHeight < 50) barHeight = 50; // Make sure we don't lose the "continue" button
		
		// Responsive width control (by default the bar runs full width at the bottom, on large resolutions it will be centred)
		/*if (mq == "full" || mq == "large-tablet") {
			var barWidth = 600;
			$("#cookieBarArea").css({"left": "50%", "width": barWidth.toString() + "px", "margin-left": (0 - (barWidth / 2)).toString() + "px"});
		}*/
		
		// Initialise the bar height and position
		$("#cookieBarArea").css({"height": (barHeight + 10).toString() + "px"});
		_cbBar.css({"margin-top": barHeight.toString() + "px"});
		_cbBar.css({"height": barHeight.toString() + "px"});
		_cbBarHeight = (barHeight + 10); // Allow for shadow
		
		// Show the bar!
		_cbBar.animate({"margin-top": "10px"}, 500);
		
		// Start timeout to hide cookie bar again 
		timeoutCookieBar();
		
		// Set a cookie so the bar doesn't appear again for at least a year
		createCookie("dontShowCookieBar", "1", 365);
	}
}

// Function to slide cookie bar out of view
function hideCookieBar() {
	if ($) {
		// Hide the bar
		_cbBar.animate({"margin-top": _cbBarHeight.toString() + "px"}, 500, function() { $("#cookieBarArea").remove(); });
	}
}

// Function to create a cookie
function createCookie(name, value, days) {
	if (days) {
		var date = new Date();
		date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
		var expires = "; expires=" + date.toGMTString();
	} else var expires = "";
	document.cookie = name + "=" + value + expires + "; path=/";
}

// Function to read a cookie
function readCookie(name) {
	if(document.cookie) {
		var nameEQ = name + "=";
		var ca = document.cookie.split(";");
		for (var i=0;i < ca.length;i++) {
			var c = ca[i];
			while (c.charAt(0) == " ") c = c.substring(1, c.length);
			if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
		}
		return null;
	} else return null;
}

// Function to remove a cookie
function eraseCookie(name) {
	createCookie(name, "", -1);
}